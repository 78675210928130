import axiosPlugin from "../plugins/axios";
import toastPlugin from "../plugins/messages";
import router from "../router";
const axios = axiosPlugin._axios;
const toast = toastPlugin.toast;
const getMessage = toastPlugin.getMessage;

const findAll = async(byOrganization = false) => {
	try {
		let url = "/users";
		if(byOrganization === true){
			url += "?byOrganization=true";
		}
		const response = await axios.get(url);
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de récupérer les utilisateurs" +
        getMessage(error.response.status)
		);
		throw error;
	}
};
const findById = async id => {
	try {
		const response = await axios.get(`/users/${id}`);
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de récupérer l'utilisateur" +
        getMessage(error.response.status)
		);
		throw error;
	}
};
const findByOrganization = async organizationId => {
	try {
		const response = await axios.get(`/organizations/${organizationId}/users`);
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de récupérer les utilisateurs" +
        getMessage(error.response.status)
		);
		throw error;
	}
};
const findByAgencies = async agencyIds => {
	try {
		let url = "/users";
		agencyIds.forEach((agencyId, index) => {
			index === 0
				? (url += `?agency[]=${agencyId}`)
				: (url += `&agency[]=${agencyId}`);
		});

		const response = await axios.get(url);
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de récupérer les utilisateurs" +
        getMessage(error.response.status)
		);
		throw error;
	}
};
const findByAgenciesAndJob = async(agencyIds, job = null) => {
	try {
		let url = "/users";

		if(job !== null){
			url += `?job=${job}`;
		}

		agencyIds.forEach((agencyId, index) => {
			index === 0 && job === null
				? (url += `?agencies[]=${agencyId}`)
				: (url += `&agencies[]=${agencyId}`);
		});

		const response = await axios.get(url);
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de récupérer les utilisateurs" +
        getMessage(error.response.status)
		);
		throw error;
	}
};
const findBySites = async siteIds => {
	try {
		let url = "/users";
		siteIds.forEach((siteId, index) => {
			index === 0
				? (url += `?sites[]=${siteId}`)
				: (url += `&sites[]=${siteId}`);
		});

		const response = await axios.get(url);
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de récupérer les utilisateurs" +
        getMessage(error.response.status)
		);
		throw error;
	}
};
const findBySitesAndJob = async(siteIds, job = null) => {
	try {
		let url = "/users";

		if(job !== null){
			url += `?job=${job}`;
		}

		siteIds.forEach((siteId, index) => {
			index === 0 && job === null
				? (url += `?sites[]=${siteId}`)
				: (url += `&sites[]=${siteId}`);
		});

		const response = await axios.get(url);
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de récupérer les utilisateurs" +
        getMessage(error.response.status)
		);
		throw error;
	}
};
const findByJobAndOrganization = async(job, organizationId) => {
	try {
		const response = await axios.get(
			"/users?job=" + job + "&organizationId=" + organizationId
		);
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de récupérer les utilisateurs" +
        getMessage(error.response.status)
		);
		throw error;
	}
};
const findByRole = async role => {
	try {
		const response = await axios.get("/users?role=" + role);
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de récupérer les utilisateurs" +
        getMessage(error.response.status)
		);
		throw error;
	}
};
const findByCampaign = async(campaignId, params) => {
	try {
		const response = await axios.get(`/campaigns/${campaignId}/users`, {
			params
		});
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de récupérer les utilisateurs" +
        getMessage(error.response.status)
		);
		throw error;
	}
};
const create = async(user, sendEmail = false) => {
	try {
		const response = await axios.post("/users", user);

		if(true === sendEmail){
			await sendInvite(response.data.id, user.email);
		}

		toast("success", "Utilisateur créé !");

		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de créer l'utilisateur : " +
        router.app.$t(error.response.data.message)
		);
		throw error;
	}
};
const sendInvite = async(id, email, logSuccess = false) => {
	try {
		await axios.post(
			`/users/${id}/account-recoveries`,
			{email},
			{showLoader: false}
		);
		if(true === logSuccess) toast("success", "Invitation envoyée à " + email);
	}
	catch (error){
		toast(
			"error",
			"Impossible d'envoyer l'invitation" + getMessage(error.response.status)
		);
		throw error;
	}
};

const askRecovery = async email => {
	try {
		await axios.post("/account-recoveries", {email}, {showLoader: false});
	}
	catch (error){
		toast(
			"error",
			"Impossible d'envoyer le lien de récupération" +
        getMessage(error.response.status)
		);
		throw error;
	}
};

const edit = async(id, user) => {
	try {
		const response = await axios.put(`/users/${id}`, user);

		toast("success", "Utilisateur modifié !");

		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de modifier l'utilisateur" + getMessage(error.response.status)
		);
		throw error;
	}
};
const editPassword = async(id, passwords) => {
	try {
		await axios.put(`/users/${id}/password`, passwords);
		toast("success", "Votre mot de passe a bien été changé");
	}
	catch (error){
		if(status === 400){
			toast("error", "Mot de passe identique au mot de passe actuel");
		}
		else {
			toast("error", "Mot de passe actuel invalide");
		}
		throw error;
	}
};

const resetPassword = async(token, password) => {
	try {
		await axios.post("/reset-password/" + token, {
			password
		});
		toast("success", "Votre mot de passe a bien été changé");
	}
	catch (error){
		toast(
			"error",
			"Impossible de modifier le mot de passe : " +
        router.app.$t(error.response.data.message)
		);
		throw error;
	}
};

// delete is a reserved word
const remove = async id => {
	try {
		await axios.delete(`/users/${id}`);
	}
	catch (error){
		toast(
			"error",
			"Impossible de supprimer l'utilisateur" +
        getMessage(error.response.status)
		);
		throw error;
	}
};

const patch = async(userId, data) => {
	try {
		await axios.patch(`/users/${userId}`, data);
	}
	catch (error){
		toast(
			"error",
			"Impossible de modifier l'utilisateur" + getMessage(error.response.status)
		);
		throw error;
	}
};

const uploadAvatar = async(userId, formData) => {
	try {
		const response = await axios.post(
			`/users/${userId}/avatar/upload`,
			formData
		);

		return await patch(userId, {profilePicturePath: response.data.path});
	}
	catch (error){
		toast(
			"error",
			"Impossible d'envoyer l'image" + getMessage(error.response.status)
		);
		throw error;
	}
};

export default {
	findAll,
	findById,
	findByOrganization,
	findByAgencies,
	findByAgenciesAndJob,
	findBySites,
	findBySitesAndJob,
	findByJobAndOrganization,
	findByRole,
	findByCampaign,
	create,
	sendInvite,
	askRecovery,
	edit,
	editPassword,
	resetPassword,
	delete: remove,
	patch,
	uploadAvatar
};
