import Vue from "vue";
import httpErrors from "../service/httpErrors";
import instance from "../main"

/**
 * Display a snackbar element of custom color and message
 * @param {string} color - can be success, error, info, warning...
 * @param {string} text - can be empty, a default error message is displayed in this case
 * @param {string} actionText - can be empty, the text of the action button
 * @param {function} confirmCallback - can be empty, the callback called on action button click
 */
const toast = (color, text = "", actionText, confirmCallback) => {
  instance.$root.$emit("produceMessage", {
    color,
    text,
    actionText,
    confirmCallback,
  });
};

const getMessage = (status) => {
  return httpErrors.getErrorText(status);
};

export default {
  install() {
    Vue.prototype.$toast = toast;

    /**
     * Get a standard message decoded from the response status
     * @param {int} status the http status of a request response
     * @returns string
     */
    Vue.prototype.$getMessage = getMessage 
  },
  toast,
  getMessage
};
