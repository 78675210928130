import axiosPlugin from "../plugins/axios";
import toastPlugin from "../plugins/messages";
const axios = axiosPlugin._axios;
const toast = toastPlugin.toast;
const getMessage = toastPlugin.getMessage;

const findByCampaign = async (
	id,
	category = null,
	folder = null,
	order = null,
	search = null
) => {
	try {
		let routeURL = `/campaigns/${id}/documents`;
		if (null !== category) routeURL += "?category=" + category;
		if (null !== folder) routeURL += "&folder=" + folder;
		if (null !== order) routeURL += "&order=" + order;
		if (null !== search) routeURL += "&search=" + search;
		const response = await axios.get(routeURL);
		return response.data;
	} catch (error) {
		toast(
			"error",
			"Impossible de récupérer les documents" +
        getMessage(error.response.status)
		);
		throw error;
	}
};

const findCampaignRequiredDocuments = async id => {
	try {
		let routeURL = `/campaigns/${id}/required-documents`;
		const response = await axios.get(routeURL);
		return response.data;
	} catch (error) {
		toast(
			"error",
			"Impossible de récupérer les documents requis" +
        getMessage(error.response.status)
		);
		throw error;
	}
};

const download = async (path, responseType = "blob") => {
	try {
		const response = await axios.get("files/" + path, {
			responseType
		});
		return response.data;
	} catch (error) {
		toast(
			"error",
			"Impossible de télécharger le document" +
        getMessage(error.response.status)
		);
		throw error;
	}
};

const zipSelected = async documentIds => {
	try {
		const response = await axios.post(
			"files/zip",
			{ documentIds },
			{
				responseType: "blob",
				showLoader: false
			}
		);
		return response.data;
	} catch (error) {
		toast(
			"error",
			"Impossible de télécharger le document" +
        getMessage(error.response.status)
		);
		throw error;
	}
};

const getAllCoresAsPdf = async campaignId => {
	try {
		const response = await axios.get(
			"/coring-campaigns/" + campaignId + "/cores/pdf",
			{
				responseType: "blob",
				showLoader: false
			}
		);
		return response.data;
	} catch (error) {
		toast(
			"error",
			"Impossible de télécharger le zip" + getMessage(error.response.status)
		);
		throw error;
	}
};

const exportStructuralCsv = async (campaignId, responseType = "blob") => {
	try {
		const response = await axios.get(
			"coring-campaigns/" + campaignId + "/csv-export",
			{
				responseType,
				showLoader: false
			}
		);
		return response.data;
	} catch (error) {
		toast(
			"error",
			"Impossible d'exporter le CSV : " + getMessage(error.response.status)
		);
		throw error;
	}
};

const generateDownloadLink = async payload => {
	try {
		const response = await axios.post("/download-links", payload);
		return response.data;
	} catch (error) {
		toast(
			"error",
			"Impossible de générer le lien" + getMessage(error.response.status)
		);
		throw error;
	}
};

const getDownloadLink = async tokenId => {
	try {
		const response = await axios.get(`/download-links/${tokenId}`);
		return response.data;
	} catch (error) {
		toast(
			"error",
			"Impossible de trouver le document" + getMessage(error.response.status)
		);
		throw error;
	}
};

const downloadZipFromToken = async tokenId => {
	try {
		const response = await axios.get(`/download-links/${tokenId}/files`, {
			responseType: "blob",
	  		showLoader: true
		});
		return response.data;
	} catch (error) {
		toast(
			"error",
			"Impossible de télécharger le zip" + getMessage(error.response.status)
		);
		throw error;
	}
};

/**
 * Upload document to S3 and save object to DB, s3Path will be automatically overwritten
 * @param {FormData} formData files informations and payload
 * @param {object} document infos to store about the document entity
 */
const upload = async (formData, document) => {
	try {
		let response = await axios.post("/documents/upload", formData);
		const s3Path = response.data.path;
		document = await create({ ...document, s3Path });
		toast("success", "Document ajouté !");
		return document;
	} catch (error) {
		toast(
			"error",
			"Impossible d'uploader le document" + getMessage(error.response.status)
		);
		throw error;
	}
};

/** Not exported, use upload documents instead */
const create = async document => {
	try {
		const response = await axios.post("/documents", document);
		return response.data;
	} catch (error) {
		toast("error", "Une erreur est survenue avec le document " + document.name);
		throw error;
	}
};

const require = async requiredDocument => {
	try {
		const response = await axios.post("/required-documents", requiredDocument);
		toast("success", "Demande effectuée !");
		return response.data;
	} catch (error) {
		toast(
			"error",
			"Impossible d'effectuer la demande" + getMessage(error.response.status)
		);
		throw error;
	}
};

const review = async (requiredDocumentId, status) => {
	try {
		const response = await axios.patch(
			`/required-documents/${requiredDocumentId}`,
			{ status }
		);
		return response.data["pre-signed-url"];
	} catch (error) {
		toast(
			"error",
			"Impossible de mettre à jour le document" +
        getMessage(error.response.status)
		);
		throw error;
	}
};

const getMergedPDF = async (documentsIds, responseType = "blob") => {
	try {
		const response = await axios.post(
			"/pdf/merge",
			{ documentsIds },
			{ responseType }
		);
		return response.data;
	} catch (error) {
		toast(
			"error",
			"Impossible de fusionner les documents" +
        getMessage(error.response.status)
		);
		throw error;
	}
};

const deleteRequiredDocument = async requiredDocumentId => {
	try {
		const response = await axios.delete(
			"/required-documents/" + requiredDocumentId
		);
		return response.data;
	} catch (error) {
		toast(
			"error",
			"Impossible de supprimer la demande de document." +
        getMessage(error.response.status)
		);
		throw error;
	}
};

export default {
	findByCampaign,
	findCampaignRequiredDocuments,
	download,
	generateDownloadLink,
	getDownloadLink,
	downloadZipFromToken,
	require,
	upload,
	review,
	getMergedPDF,
	exportStructuralCsv,
	zipSelected,
	deleteRequiredDocument,
	getAllCoresAsPdf,
	create
};
