// initial state
const state = () => ({
  all: []
});

// getters
const getters = {};

// mutations
const mutations = {
  addCampaign(state, campaign) {
    state.all.unshift(campaign);
  },
  setCampaigns(state, campaigns) {
    state.all = campaigns;
  },
  removeCampaign(state, campaignId) {
    state.all = state.all.filter(campaign => campaign.id !== campaignId);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations
};
