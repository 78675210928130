"use strict";

import Vue from "vue";
import axios from "axios";
import auth from "../service/auth";
import store from "../store";

axios.defaults.showLoader = true;
axios.defaults.baseURL =
  process.env.VUE_APP_BASE_URL || "http://localhost:8000";

if (auth.isLogged() === true) {
  axios.defaults.headers.common["Authorization"] = "Bearer " + auth.getJwt();
} else if (auth.isLoggedGuest() === true) {
  axios.defaults.headers.common["Authorization"] = auth.getGuestToken();
}

let config = {
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    if (response.config.showLoader) {
      store.dispatch("loader/done");
    }
    // Do something with response data
    return response;
  },
  function (error) {
    let response = error.response;

    if (response.config.showLoader) {
      store.dispatch("loader/done");
    }

    if (error.response?.data?.message === "Expired JWT Token") {
      _axios
        .post("/refresh_token", { refresh_token: auth.getRefreshToken() })
        .then(response => {
          auth.login(response.data.token, response.data.refresh_token);
          window.location.reload();
        })
        .catch(error => {
          auth.logout();
          window.location.replace("/login");
          return Promise.reject(error);
        });
    }
    return Promise.reject(error);
  }
);

_axios.interceptors.request.use(
  config => {
    if (config.showLoader) {
      store.dispatch("loader/pending");
    }
    if (auth.isLoggedGuest() === true && false === auth.isLogged()) {
      const guestToken = localStorage.getItem("guest_token");
      if (guestToken) {
        config.headers["Authorization"] = guestToken;
      }
    }

    return config;
  },
  error => {
    if (error.config.showLoader) {
      store.dispatch("loader/done");
    }
    return Promise.reject(error);
  }
);

export default {
  install() {
    Vue.prototype.$axios = _axios;
  },
  _axios
};
