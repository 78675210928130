import {api} from "../../api";

export default {
	namespaced: true,
	strict: false,

	state: {
		projects: false,
		organizations: false,
		customerOrganizations: false,
		site: false,
		customerSite: false,

		filterName: false,
		filterOrga: false,
		filterSite: false,
		filterCampaign: false,
		filterType: false
	},

	mutations: {
		SET_PROJECTS(state, projects){
			if(projects === false){
				state.projects = false;
				state.site = false;
				state.organizations = false;
				return;
			}
			state.organizations = [];
			state.customerOrganizations = [];
			state.site = [];
			state.customerSite = [];
			for(const {site} of projects){
				const {organization: {name: orga}} = site;
				if(state.site.indexOf(site.name) === -1)state.site.push(site.name);
				if(state.organizations.indexOf(orga) === -1)state.organizations.push(orga);
			}
			for(const {customerSite} of projects){
				const {organization: {name: orga}} = customerSite;
				if(state.customerSite.indexOf(customerSite.name) === -1)state.customerSite.push(customerSite.name);
				if(state.organizations.indexOf(orga) === -1)state.customerOrganizations.push(orga);
			}
			state.customerOrganizations.sort();
			state.site.sort();
			state.projects = projects;
		},
		SET_FILTERNAME(state, value){
			if(value === "" || value === null)state.filterName = false;
			else state.filterName = value;
		},
		SET_FILTERORGA(state, value){
			if(value === "" || value === null)state.filterOrga = false;
			else state.filterOrga = value;
		},
		SET_FILTERSITE(state, value){
			if(value === "" || value === null)state.filterSite = false;
			else state.filterSite = value;
		},
		SET_FILTERCAMPAIGN(state, value){
			if(value === "" || value === null)state.filterCampaign = false;
			else state.filterCampaign = value;
		},
		SET_FILTERTYPE(state, value){
			if(value === "" || value === null)state.filterType = false;
			else state.filterType = value;
		},
		SET_PROJECT(state, value){
			let index = state.projects.findIndex(e => e.id = value.id);
			state.projects[index] = value;
			state.projects = [...state.projects];
		}
	},

	getters: {
		projects(state){
			return state.project;
		},
		filterProjects(state){
			let projects = state.projects;
			if(projects === false) return [];
			if(state.filterName !== false)projects = projects.filter(value => value.name.toLowerCase().indexOf(state.filterName.toLowerCase()) > -1);
			if(state.filterOrga !== false)projects = projects.filter(({customerSite: {organization: {name}}}) => name === state.filterOrga);
			if(state.filterSite !== false)projects = projects.filter(({site: {name}}) => name === state.filterSite);
			if(state.filterCampaign !== false)projects = projects.filter(project => project.campaigns.find(campaign => campaign.name.toLowerCase().indexOf(state.filterCampaign.toLowerCase()) > -1));
			if(state.filterType !== false)projects = projects.filter(project => project.campaigns.find(campaign => campaign.type.indexOf(state.filterType) > -1));
			return projects;
		},

		organizations(state){
			return state.organizations || [];
		},
		customerOrganizations(state){
			return state.customerOrganizations || [];
		},
		site(state){
			return state.site || [];
		},
		customersite(state){
			return state.customersite || [];
		},

		filterName(state){
			return state.filterName || "";
		},
		filterOrga(state){
			return state.filterOrga || "";
		},
		filterSite(state){
			return state.filterSite || "";
		},
		filterCampaign(state){
			return state.filterCampaign || "";
		},
		filterType(state){
			return state.filterType || "";
		},
	},

	actions: {
		async initStore({commit, dispatch, rootState}, archived){
			await dispatch("user/waitLoadUser", undefined, {root: true});
			await Promise.all([dispatch("updateWorkspaces"),]);

			commit(
				"SET_PROJECTS", 
				await api.workspaces.findByUser(
					rootState.user.current.userId,
					{isArchived: archived}
				)
			);
		},

		purgeStore({commit}){
			commit("SET_PROJECTS", false);
		},

		async updateWorkspaces({commit, rootState}, archived){
			commit("SET_PROJECTS", await api.workspaces.findByUser(
				rootState.user.current.userId,
				{isArchived: archived}
			)
			);
		},

		async updateWorkspace({commit}, project){
			commit("SET_PROJECT", project);
		}
	}
};
