const listenToEscape = (callback) => {
  window.addEventListener("keydown", (e) => {
    if (e.key != "Escape") return;
    callback();
  });
};

export default {
  listenToEscape,
};
