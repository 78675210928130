<template>
	<div class="snackbar-container">
		<singleSnackbar
		v-for="(message, index) in messages"
		:key="message.id"
		:message="message"
		:timeout="timeout"
		@closeSnackbar="hide(message.id)"
		:style="{ 'margin-bottom': calcMargin(index) }"
		/>
	</div>
</template>

<script>
import singleSnackbar from "@/components/system/messages/SingleSnackbar.vue";

export default {
	components: {
		singleSnackbar,
	},
	data(){
		return {
			messages: [],
			timeout: 8000,
			validationErrors: [],
		};
	},
	methods: {
		calcMargin(i){
			return i * 60 + "px";
		},
		hide(i){
			this.messages.splice(
				this.messages.findIndex((m) => m.id == i),
				1
			);
		},
		isMessageValid(message){
			this.validationErrors = [];
			let isValid = true;

			// Check color
			if(!message.color){
				isValid = false;
				this.validationErrors.push("message.color is required");
			}
			else if(typeof message.color !== "string"){
				isValid = false;
				this.validationErrors.push(
					"message.color must be a string, " +
            typeof message.color +
            " provided"
				);
			}

			// check that if an action is provided, there is a callback of type function
			if(message.actionText){
				if(!message.confirmCallback){
					isValid = false;
					this.validationErrors.push(
						"you have set an action, confirmCallback is required"
					);
				}
				else if("function" !== typeof message.confirmCallback){
					isValid = false;
					this.validationErrors.push(
						"confirmCallback must be a function, " +
              typeof message.confirmCallback +
              " provided"
					);
				}
			}

			return isValid;
		},
		pushMessage(message){
			if(!this.isMessageValid(message)) return console.error("invalid message : ", {
				message,
				errors: this.validationErrors,
			});

			message.show = true;
			message.id = this.messages.length + 1;
			if(this.messages.find(v => v.text === message.text) !== undefined) this.messages = this.messages.filter(v => v.text !== message.text);
			this.messages.push(message);
		},
	},
	mounted(){
		this.$root.$on("produceMessage", this.pushMessage);
	},
};
</script>

<style lang="scss" scoped>
.snackbar-container .v-snack:not(.v-snack--avbsolute) {
  height: auto !important;
}
</style>
