<template>
  <div class="leftView-container">
    <div class="logo"></div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.leftView-container {
  height: 100vh;
  width: 50vw;
  display: flex;
  align-items: center;
  background-color: #FBF5F3;
  justify-content: center;

  .logo {
    background-image: url(../../assets/images/MAORIE_RVB-low_black.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 70%;
    height: 200px;
  }
  .movie {
    display: flex;
    justify-content: center;
    .presentationMovie {
      width: 90%;
      height: auto;
    }
  }
}
</style>
