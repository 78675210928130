<template>
	<v-app>
		<template>
			<Ops/>
			
			<div id="popup"/>

			<div id="app">
				<router-view/>
			</div>

			<SystemSnackbar/>

			<NotificationCard
			v-if="isUserLogged"
			class="notificationComponent"
			/>
		</template>
	</v-app>
</template>

<script>
import auth from "./service/auth";
import keyboard from "./service/keyboard";
import Ops from "@/components/Ops.vue";
import NotificationCard from "@/components/notifications/Notifications.card.vue";

export default {
	components: {
		Ops,
		NotificationCard
	},
	computed: {
		isUserLogged(){
			return auth.isLogged();
		}
	},
	methods: {
		checkAuth(){
			if(null === this.$route.name) return setTimeout(this.checkAuth, 200);
			if(
				auth.isLogged() === false &&
        auth.isLoggedGuest() === false &&
        this.$route.name !== "login" &&
        this.$route.name !== "download-zip" &&
        this.$route.name !== "share-link-auth" &&
        this.$route.name !== "edit-password"
			){
				this.$router.push({name: "login"});
			}
		}
	},
	mounted(){
		this.checkAuth();

		keyboard.listenToEscape(() => {
			this.$root.$emit("closeCurrent");
		});
	}
};
</script>

<style lang="scss">
@import "./assets/scss/_variables.scss";

.m_brown {
  color: $brown !important;
}
html {
  overflow-y: hidden !important;
}

.notificationComponent {
  position: fixed;
  top: 150px;
  left: 270px;
  background-color: rgb(252, 252, 252) !important;
  width: 500px;
}
</style>
