import axiosPlugin from "../../plugins/axios";
const axios = axiosPlugin._axios;

export default {
	namespaced: true,
	strict: false,
	state: {
		cores: false,
		selectedCore: false,
		campaigns: false,
		projects: false,
		organizations: false,
		selectedOrganization: false,

		filter: false,
		filterValue: {
			PAH: [
				1000, 500, 50, 0
			],
			TH: [500, 0],
			asbestos: [true, false],
		},
		filterCampaign: [],

		map: {},
	},

	mutations: {
		SET_CORES(state, cores){
			state.cores = cores;
		},
		SET_CAMPAIGNS(state, campaigns){
			state.campaigns = campaigns;
		},
		SET_PROJECTS(state, projects){
			state.projects = projects;
		},
		SET_SELECTED_CORE(state, selectedCore){
			state.selectedCore = selectedCore;
		},
		SET_ORGANIZATIONS(state, organizations){
			state.organizations = organizations;
		},
		SET_SELECTED_ORGANIZATIONS(state, organization){
			state.selectedOrganization = organization;
		},

		SET_FIlTER(state, value){
			state.filter = value;
		},
		SET_FIlTER_PAH(state, value){
			state.filterValue.PAH = value;
		},
		SET_FIlTER_TH(state, value){
			state.filterValue.TH = value;
		},
		SET_FIlTER_ASBESTOS(state, value){
			state.filterValue.asbestos = value;
		},
		SET_FIlTER_CAMPAIGN(state, value){
			state.filterCampaign = value;
		},

		SET_MAP(state, map){
			state.map = map;
		},
	},

	getters: {
		cores(state){
			return state.cores;
		},
		selectedCore(state){
			return state.selectedCore || {};
		},
		campaigns(state){
			return state.campaigns;
		},
		projects(state){
			return state.projects;
		},
		organizations(state){
			return state.organizations;
		},
		selectedOrganization(state){
			return state.selectedOrganization;
		},

		filter(state){
			return state.filter;
		},
		filterValue(state){
			return state.filterValue;
		},
		filterCampaign(state){
			return state.filterCampaign;
		},

		map(state){
			return state.map;
		},
	},

	actions: {
		async initStore({dispatch, commit}, id){
			let [
				{data: cores},
				{data: campaigns},
				{data: projects},
				{data: organizations},
			] = await Promise.all([
				axios.get(`/users/${id}/macro-cores`),
				axios.get(`/users/${id}/macro-campaigns`),
				axios.get(`/users/${id}/macro-projects`),
				axios.get(`/users/${id}/macro-organizations`)
			]);
			commit("SET_CORES", cores);
			commit("SET_CAMPAIGNS", campaigns);
			commit("SET_PROJECTS", projects);
			commit("SET_ORGANIZATIONS", organizations);
			commit("SET_SELECTED_ORGANIZATIONS", organizations[0]?.id || false);
			projects.forEach(e => dispatch("selectProject", e.id));
		},

		purgeStore({commit}){
			commit("SET_CORES", false);
			commit("SET_CAMPAIGNS", false);
			commit("SET_PROJECTS", false);
			commit("SET_FIlTER_CAMPAIGN", []);
			commit("SET_SELECTED_CORE", false);
			commit("SET_MAP", false);
		},

		selectCore({commit}, core){
			commit("SET_SELECTED_CORE", core);
		},

		unSelectCore({commit}){
			commit("SET_SELECTED_CORE", false);
		},

		setFilterType({commit}, type){
			commit("SET_FIlTER", type);
		},
		addValueToFilter({commit, state}, value){
			commit(
				"SET_FIlTER_" + state.filter.toUpperCase(), 
				[...state.filterValue[state.filter], value]
			);
		},
		removeValueToFilter({commit, state}, value){
			commit(
				"SET_FIlTER_" + state.filter.toUpperCase(), 
				state.filterValue[state.filter].filter(v => v !== value)
			);
		},
		selectProject({commit, state}, id){
			commit(
				"SET_FIlTER_CAMPAIGN",
				[...state.filterCampaign, ...state.campaigns.filter(v => v.projectId === id).map(v => v.id)]
			);
		},
		unSelectProject({commit, state}, id){
			commit(
				"SET_FIlTER_CAMPAIGN",
				state.filterCampaign.filter(v => v.projectId !== id)
			);
		},
		selectCampaign({commit, state}, id){
			commit(
				"SET_FIlTER_CAMPAIGN",
				[...state.filterCampaign, id]
			);
		},
		unSelectCampaign({commit, state}, id){
			commit(
				"SET_FIlTER_CAMPAIGN",
				state.filterCampaign.filter(v => v !== id)
			);
		},
	}
};
