<template>
  <div id="ops"></div>
</template>

<script>
export default {
  mounted() {
    console.log("===== ===== ===== OPS ===== ===== ===== ");
    console.log(
      `UI IMAGE | ${process.env.VUE_APP_FRONT_IMAGE || "maorie-ui"}@${
        process.env.VUE_APP_FRONT_TAG || "latest-loc"
      }`
    );
    console.log(
      `API URL  | ${process.env.VUE_APP_BASE_URL || "http://localhost:8000/"}`
    );
    console.log(`BRANCH   | ${process.env.VUE_APP_BRANCH || "default"}`);
    console.log("TEST :: ARGO CD");
    console.log("TEST :: DEMONSTRATION 2");
    console.log("===== ===== ===== ::: ===== ===== ===== ");
  }
};
</script>

<style lang="scss" scoped>
#ops {
  display: none;
}
</style>
