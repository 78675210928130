<template>
	<div
	class="popup-div"
	@click="launcheAnim"
	popup-slot
	>
		<section
		@click="$event.stopPropagation()"
		class="popup"
		ref="container"
		:class="{
			[childProps.class]: true
		}"
		/>
	</div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
	props: {
		child: {},
		childProps: {}
	},
	data(){
		return {
			el: undefined
		};
	},
	methods: {
		launcheAnim(){
			this.$refs.container.classList.add("anim-popup");
		}
	},
	mounted(){
		let div = document.createElement("div");
		this.$refs.container.append(div);
		this.$refs.container.onanimationend = () => this.$refs.container.classList.remove("anim-popup");
		this.el = new this.child({propsData: this.childProps});
		this.el.$mount(div);
		this.el.emit = (arg) => this.emit(arg);
		
	}
});
</script>

<style lang="scss">
div[popup-slot]{
	position: fixed;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 5;
	background: rgba(0,0,0,0.5);
	display: flex;
	justify-content: center;
	align-items: center;

	.popup{
		user-select: none;
		padding: 20px;
		background: #fff;
		border-radius: 4px;
	}

	.anim-popup{
		animation: popup 200ms ease-in-out forwards;
	}
}

@keyframes popup {
	from {
		scale: 1;
	}

	50% {
		scale: 1.05;
	}

	to {
		scale: 1;
	}
}
</style>
