<template>
  <div class="Popin">
    <v-card flat outlined>
      <v-card-title class="center justify-center" align-items>
        <slot name="title">
          <h3>{{ title }}</h3></slot
        >
      </v-card-title>
      <slot>content</slot>

      <v-card-actions>
        <slot name="actions">
          <LowButtonSlot @click="$emit('close')">Annuler</LowButtonSlot>
          <v-spacer></v-spacer>
          <HighButtonSlot @click="$emit('validate')">Valider</HighButtonSlot>
        </slot>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ["title"],
};
</script>

<style lang="scss" scoped>
.Popin {
  width: calc(100vw + 2px);
  height: calc(100vh + 2px);
  background: rgba(0, 0, 0, 0.75);
  position: fixed;
  top: -1px;
  left: -1px;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;

  .v-card {
    padding: 20px;
    width: 66%;
  }
}
</style>
