import axiosPlugin from "../../plugins/axios";
import toastPlugin from "../../plugins/messages";
const axios = axiosPlugin._axios;
const toast = toastPlugin.toast;
const getMessage = toastPlugin.getMessage;

const findByProvider = async (id) => {
  try {
    const response = await axios.get(`/providers/${id}/prestations`);
    return response.data;
  } catch (error) {
    toast(
      "error",
      "Impossible de récupérer les prestations" +
        getMessage(error.response.status)
    );
    throw error;
  }
};

const findByLayer = async (id) => {
  try {
    const response = await axios.get(`/cores-layers/${id}/layer-prestations`, {
      showLoader: false,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default {
  findByProvider,
  findByLayer,
};
