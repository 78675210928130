// initial state
const state = () => ({
  all: [],
  count: 0,
  countUnread: 0
});

// getters
const getters = {};

// mutations
const mutations = {
  setNotifications(state, notifications) {
    state.all = notifications;
  },
  setCount(state, count) {
    state.count = count;
  },
  setCountUnread(state, count) {
    state.countUnread = count;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
