<template>
	<div button-slot>
		<div
		v-if="_tooltip !== ''"
		class="tooltip-div"
		>
			<span
			class="tooltip"
			:class="{[_tooltipDir]: true}"
			>
				{{ _tooltip }}
			</span>
		</div>

		<v-btn
		@click="clicked"
		class="button-slot"
		:class="{['theme-' + _theme]: true, 'square': !$slots.default, 'full-rounded': _fullRounded, 'activated': isActive}"
		:to="_to"
		:disabled="_disabled"
		:type="_type"
		:loading="_loading || loading"
		:small="_small"
		elevation="0"
		>
			<v-icon
			v-if="_icon && _icon.startsWith('mdi')"
			:class="{[_iconClass]: true, [_theme]: true}"
			:style="{'--BS-icon-size': _iconSize}"
			>
				{{ _icon }}
			</v-icon>

			<img 
			v-else-if="_icon && _icon.startsWith('data:image')"
			:class="{[_iconClass]: true, [_theme]: true}"
			:style="{'--BS-icon-size': _iconSize}"
			:src="_icon"
			/>

			<p
			v-if="windowWidth >= _res && $slots.default"
			:class="{'ml': _icon}"
			>
				<slot/>
			</p>
		</v-btn>

		<input 
		v-if="$listeners.selectFile !== undefined"
		ref="fileInput"
		type="file" 
		style="display: none;"
		@change="$emit('selectFile', $event)"
		:accept="_selectFileAccept"
		/>
	</div>
</template>
  
<script>
import createPopup from "../../../utils/popup";
import ButtonPopupSlot from "../popup/Button.Popup.slot";

export default {
	name: "ButtonSlot",
	props: {
		"_to": {

		},
		"_disabled": {
			type: Boolean,
			default: false,
			require: false
		},
		"_type": {
			type: String,
			default: "button",
			require: false
		},
		"_loading": {
			type: Boolean,
			default: false,
			require: false
		},
		"_small": {

		},
		"_icon": {
			type: String,
			default: "",
			require: false
		},
		"_icon-class": {
			type: String,
			default: "",
			require: false
		},
		"_icon-size": {
			type: String,
			default: "1.35em",
			require: false
		},
		"_res": {
			type: Number,
			default: 0,
			require: false
		},
		"_theme": {
			type: String,
			default: "brown",
			require: false
		},
		"_full-rounded": {
			type: Boolean,
			default: false,
			require: false
		},
		"_tooltip": {
			type: String,
			default: "",
			require: false
		},
		"_tooltip-dir": {
			type: String,
			default: "bottom",
			require: false
		},
		"_popup": {
			default: false,
			require: false
		},
		"_auto-loading": {
			default: false,
			require: false
		},
		"_select-file-accept": {
			default: false,
			require: false
		}
	},
	data(){
		return {
			windowWidth: window.screen.width,
			isActive: false,
			loading: false,
		};
	},
	computed: {
			
	},
	methods: {
		async clicked($event){
			if(this.$listeners.selectFile !== undefined){
				this.$refs.fileInput.click();
				return;
			}

			if(this._popup !== false){
				let popup = createPopup(ButtonPopupSlot, {title: this._popup.title, subTitle: this._popup.subTitle});
				const result = await new Promise(popup.listen);
				popup.close();
				if(result === false){
					if(this._autoLoading !== false) this.loading = false;
					return;
				}
			}

			if(this._autoLoading !== false) this.loading = true;

			if(this.$listeners.reclick !== undefined){
				if(this.isActive === false){
					this.isActive = true;
					await this.$listeners.click($event);
				}
				else {
					this.isActive = false;
					await this.$listeners.reclick($event);
				}
				this.loading = false;
				return;
			}

			if(this.$listeners.click !== undefined) await this.$listeners.click($event);
			this.loading = false;

		},
		handleResize(){
			if(window.innerWidth >= this._res && this.windowWidth < this._res){
				this.windowWidth = this._res + 1;
			}
			else if(window.innerWidth < this._res && this.windowWidth > this._res){
				this.windowWidth = this._res - 1;
			}
		}
	},
	created(){
		if(this._res == 0) return;
		window.addEventListener("resize", this.handleResize);
		this.handleResize();
	},
	destroyed(){
		if(this._popup.resolve) this._popup.resolve(false);
		if(this._res == 0) return;
		window.removeEventListener("resize", this.handleResize);
	},
};
</script>
  
<style lang="scss">
	div[button-slot]{
		display: flex;
		overflow: hidden;
		position: relative;
		max-width: 100%;

		.button-slot{
			width: 100%;
			--button-height: 36px;
			background-color: var(--button-bg-color) !important;
			border-color: var(--button-bg-color) !important;
			color: var(--button-text-color) !important;
			flex: 0 1 auto;

			&.v-size--small{
				--button-height: 28px;
			}

			&.theme-none{
				--button-bg-color: transparent;
				--button-text-color: black;
				--button-disabled-opacity: 0.6;
				
				:disabled{
					background-color: #b1b1b1 !important;
				}

				&.activated{
					background-color: #b1b1b1 !important;
				}
			}

			&.theme-light-gray{
				--button-bg-color: #f5f5f5;
				--button-text-color: black;
				--button-disabled-opacity: 0.6;
				
				:disabled{
					background-color: #b1b1b1 !important;
				}

				&.activated{
					background-color: #b1b1b1 !important;
				}
			}

			&.theme-gray{
				--button-bg-color: gray;
				--button-text-color: white;
				--button-disabled-opacity: 0.6;
				
				:disabled{
					opacity: var(--button-disabled-opacity);
				}

				&.activated{
					opacity: var(--button-disabled-opacity);
				}
			}

			&.theme-brown{
				--button-bg-color: #2c0703;
				--button-text-color: white;
				--button-disabled-opacity: 0.3;

				:disabled{
					opacity: var(--button-disabled-opacity);
				}

				&.activated{
					opacity: var(--button-disabled-opacity);
				}
			}

			&.square{
				aspect-ratio: 1/1;
				padding: 0 0;
				min-width: auto;
			}

			&.full-rounded{
				border-radius: 99999px;
			}

			> span.v-btn__content{
				max-width: 100%;
				flex: 0 0 auto;
				display: flex;
				justify-content: center;
				align-items: center;
				background-color: transparent !important;
				border-color: transparent !important;
				
				> i{
					font-size: var(--BS-icon-size);
					background-color: transparent !important;
					border-color: transparent !important;	
					color: var(--button-text-color);

				}

				> img{
					width: var(--BS-icon-size);
					aspect-ratio: 1/1;
				}

				> p{
					margin: 0;
					padding: 0;
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;

					&.ml{
						margin-left: 0.5em;
					}
				}
			}
		}

		.tooltip-div{
			position: absolute;
			height: 100%;
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;

			&:has(> .left, > .right){
				flex-direction: column;
			}
		}

		.tooltip{
			background: rgb(128, 128, 128);
			z-index: 4;
			position: fixed;
			font-size: 16px;
			overflow: hidden;
			padding: 4px;
			border-radius: 4px;
			scale: 0;
			transition: all 200ms ease-in-out;
			text-align: center;
			vertical-align: middle;
			transform-origin: center center;
			color: #fff;
			user-select: none;

			&.bottom{
				align-self: flex-end;
				translate: 0 calc(100% + 5px);
			}

			&.top{
				align-self: flex-start;
				translate: 0 calc(-100% - 5px);
			}

			&.right{
				align-self: flex-end;
				translate: calc(100% + 5px) 0;
			}

			&.left{
				align-self: flex-start;
				translate: calc(-100% - 5px) 0;
			}
		}

		&:has(:hover) .tooltip:not(:hover){
			scale: 1;
		}

		.popup-div{
			position: fixed;
			height: 100%;
			width: 100%;
			top: 0;
			left: 0;
			z-index: 5;
			background: rgba(0,0,0,0.5);
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
	
</style>  


