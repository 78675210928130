import axiosPlugin from "../../plugins/axios";
import toastPlugin from "../../plugins/messages";
import router from "../../router";
const axios = axiosPlugin._axios;
const toast = toastPlugin.toast;
const getMessage = toastPlugin.getMessage;

const findAll = async(customerOnly = false) => {
	try {
		const response = await axios.get("/sites?customerOnly=" + customerOnly);
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de récupérer les sites" + getMessage(error.response.status)
		);
		throw error;
	}
};
const findById = async id => {
	try {
		const response = await axios.get(`/sites/${id}`);
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de récupérer le site" + getMessage(error.response.status)
		);
		throw error;
	}
};
const findByAgency = async agencyId => {
	try {
		const response = await axios.get(`/sites?agencyId=${agencyId}`);
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de récupérer les sites" + getMessage(error.response.status)
		);
		throw error;
	}
};
const findByAgencies = async agencyIds => {
	try {
		let url = "/sites";
		agencyIds.forEach((agencyId, index) => {
			index === 0
				? (url += `?agencies[]=${agencyId}`)
				: (url += `&agencies[]=${agencyId}`);
		});

		const response = await axios.get(url);
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de récupérer les sites" + getMessage(error.response.status)
		);
		throw error;
	}
};
const create = async site => {
	try {
		const response = await axios.post("/sites", site);

		toast("success", "Site créé !");

		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de créer le site" + getMessage(error.response.status)
		);
		throw error;
	}
};
const edit = async(id, site) => {
	try {
		const response = await axios.put(`/sites/${id}`, site);

		toast("success", "Site modifié !");

		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de modifier le site" + getMessage(error.response.status)
		);
		throw error;
	}
};
// delete is a reserved word
const remove = async(id, fallbackSiteId) => {
	try {
		await axios.delete(`/sites/${id}`, {data: {fallbackSiteId}});
	}
	catch (error){
		if(400 === error.response.status) toast("error", router.app.$t(error.response.data.message));
		else toast(
			"error",
			"Vous devez sélectionner un site de repli pour les projets et les utilisateurs du site supprimé"
		);
		throw error;
	}
};

const getSiteProviders = async id => {
	try {
		const response = await axios.get(`/sites/${id}/providers`);
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de récupérer les prestataires" +
      getMessage(error.response.status)
		);
		throw error;
	}
};

const addProviderToSite = async(siteId, providerId, data) => {
	try {
		const response = await axios.post(
			`/sites/${siteId}/providers/${providerId}`,
			data
		);
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de lier le site au prestataire" +
      getMessage(error.response.status)
		);
		throw error;
	}
};

const removeProviderFromSite = async(siteId, providerId) => {
	try {
		const response = await axios.delete(
			`/sites/${siteId}/providers/${providerId}`
		);
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de supprimer la liaison" + getMessage(error.response.status)
		);
		throw error;
	}
};

const editSiteProvider = async(siteId, providerId, data) => {
	try {
		const response = await axios.put(
			`/sites/${siteId}/providers/${providerId}`,
			data
		);
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de récupérer les sites" + getMessage(error.response.status)
		);
		throw error;
	}
};

const patchActivation = async(siteId, activation) => {
	try {
		const response = await axios.patch(
			`/sites/${siteId}`,
			activation
		);
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de modifier le site"
		);
		throw error;
	}
};

export default {
	findAll,
	findById,
	findByAgency,
	findByAgencies,
	create,
	edit,
	delete: remove,
	patchActivation,

	// providers
	getSiteProviders,
	addProviderToSite,
	editSiteProvider,
	removeProviderFromSite
};
