<template>
  <v-tooltip bottom v-if="typeof tooltip === 'string'">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="#2c0703"
        class="LowButtonSlot"
        text
        @click="clicked($event)"
        v-bind="attrs"
        v-on="on"
        :to="_to"
        :disabled="_disabled"
        :small="_small"
      >
        <slot></slot>
      </v-btn>
    </template>
    <span>{{ tooltip }}</span>
  </v-tooltip>

  <v-btn
    v-else
    color="#2c0703"
    class="LowButtonSlot"
    text
    @click="clicked($event)"
    :to="_to"
    :disabled="_disabled"
    :small="_small"
  >
    <slot></slot>
  </v-btn>
</template>
<script>
export default {
  name: "LowButtonSlot",
  props: ["_to", "_disabled", "_small", "tooltip"],
  methods: {
    clicked($event) {
      this.$emit("click", $event);
    }
  }
};
</script>
<style lang="scss" scoped>
.LowButtonSlot {
}
</style>
