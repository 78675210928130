<template>
  <v-card
    v-if="isOpen"
    :class="
      mini
        ? 'mini NotificationsCard alwaysFront'
        : 'NotificationsCard alwaysFront'
    "
    v-click-outside="closeDialog"
    outlined
    elevation-0
  >
    <div class="cardHeader">
      <v-card-title class="cardHeader__title"> Notifications </v-card-title>
      <div class="cardHeader__readAll" @click="readAllNotifications()">
        <v-icon color="#290703">mdi-checkbox-marked-circle-outline</v-icon>
        <p>Tout marquer comme lu</p>
      </div>
    </div>
    <p style="text-align: center" v-if="0 === notifications.length">
      Aucune nouvelle notification
    </p>
    <v-virtual-scroll
      class="cardNotifications"
      @scroll.native="scrolling"
      height="400"
      item-height="80"
      :items="notifications"
    >
      <template v-slot="{ item }">
        <SingleNotification
          :key="item.id"
          :notification="item"
          @close="closeDialog()"
        />
      </template>
    </v-virtual-scroll>
  </v-card>
</template>

<script>
import SingleNotification from "@/components/notifications/Notification.single.vue";

export default {
  components: {
    SingleNotification
  },
  data() {
    return {
      isOpen: false,
      page: 1,
      itemsPerPage: 10,
      isLoading: false,
      notifications: [],
      mini: false,
      isFullyLoaded: false,
    };
  },
  methods: {
    scrolling(event) {
      const element = event.currentTarget || event.target;
      if (element && element.scrollHeight - element.scrollTop === element.clientHeight && false === this.isFullyLoaded) {
        this.page += 1;
        this.getNotifications();
      }
    },
    closeDialog() {
      this.isOpen = false;
      this.isFullyLoaded = false;
      this.notifications = [];
    },
    openDialog(mini) {
      this.mini = mini;
      this.isFullyLoaded = false;
      this.isOpen = true;
      this.notifications = [];
      this.page = 1;
      this.getNotifications();
    },
    async getNotifications() {
      const notifications = await this.$api.notifications.findUserNotifications(
        this.page,
        this.itemsPerPage
      );

      if (0 === notifications.length) {
        this.isFullyLoaded = true;
      }

      if (false === this.isFullyLoaded) {
        notifications.forEach((notification) => {
          if (
            undefined !==
            this.notifications.find((item) => item.id === notification.id)
          ) {
            return;
          }
          this.notifications.push(notification);
        });
      }

      let all = await this.$api.notifications.findUserNotifications(1, 99);
      let allUnread = await this.$api.notifications.findUserNotifications(
        1,
        99,
        false
      );

      this.$store.commit("notifications/setCount", all.length);
      this.$store.commit("notifications/setCountUnread", allUnread.length);
    },
    readAllNotifications() {
      this.$api.notifications.readAllNotifications();
      this.getNotifications();
      this.isOpen = false;
    }
  },
  watch: {
    isOpen() {
      if (false === this.isOpen) this.page = 1;
    },
  },
  mounted() {
    this.$root.$on("openNotifications", this.openDialog);

    this.getNotifications();
  }
};
</script>

<style lang="scss">
.NotificationsCard {
  &.mini {
    transform: translate(-200px, -100px);
  }
  border-color: var(--primary-color) !important;

  padding-bottom: 16px;
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;

  .cardHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__title {
      color: #290703;
      margin-left: 16px;
      font-size: 24px;
    }

    &__readAll {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #290703;
      cursor: pointer;

      p {
        margin-bottom: 0;
        margin-left: 5px;
        padding-right: 16px;
      }
    }
  }
}
.alwaysFront {
  z-index: 20000;
}
</style>
