import axiosPlugin from "../../plugins/axios";
import toastPlugin from "../../plugins/messages";
const axios = axiosPlugin._axios;
const toast = toastPlugin.toast;
const getMessage = toastPlugin.getMessage;

const edit = async (layerId, layer) => {
	try {
		const response = await axios.put(`/cores-layers/${layerId}`, layer);
		return response.data;
	} catch (error) {
		toast(
			"error",
			"Impossible de modifier la couche" + getMessage(error.response.status)
		);
		throw error;
	}
};
const create = async layer => {
	try {
		const response = await axios.post("/cores-layers", layer);
		return response.data;
	} catch (error) {
		toast(
			"error",
			"Impossible de créer la couche" + getMessage(error.response.status)
		);
		throw error;
	}
};
const get = async coreId => {
	try {
		const response = await axios.get(`/cores/${coreId}/cores-layers`);
		return response.data;
	} catch (error) {
		toast(
			"error",
			"Impossible de créer la couche" + getMessage(error.response.status)
		);
		throw error;
	}
};
const update = async (coreId, coreLayers) => {
	try {
		const response = await axios.put(`/cores/${coreId}/cores-layers`, coreLayers);
		return response.data;
	} catch (error) {
		toast(
			"error",
			"Impossible de mettre a jour les couche" + getMessage(error.response.status)
		);
		throw error;
	}
};
const copyLayersToCore = async(coreToCopyId, targetCoreId) => {
	try {
		const response = await axios.post("/actions/copy-layers-to-core", {coreToCopy: coreToCopyId, targetCores: targetCoreId});
		return response.data;
	} catch (error) {
		toast();
		throw error;
	}
};

export default {
	edit,
	create,
	get,
	update,
	copyLayersToCore
};
