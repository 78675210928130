import Vue from "vue";
import App from "./App.vue";

import router from "./router";
import store from "./store";

import axios from "./plugins/axios";
import vuetify from "./plugins/vuetify";
import moment from "./plugins/moment";
import messages from "./plugins/messages";
import i18n from "./plugins/i18n";
import security from "./plugins/security";
import api from "./api";
import rules from "./plugins/rules";
//import VueHtml2Canvas from 'vue-html2canvas';
 




import { capitalize } from "./utils/capitalizer.js";

/** All global components */
import "./service/componentsRegistry";

Vue.config.productionTip = false;

Vue.use(messages);
Vue.use(security);
Vue.use(api);
Vue.use(axios);
Vue.use(rules);
//Vue.use(VueHtml2Canvas);

Vue.prototype.$cap = capitalize;

const vue = new Vue({
  vuetify,
  router,
  moment,
  store,
  i18n,
  render: (h) => h(App),
})
vue.$mount("#app");

export default vue