import authorization from "./authorizations.utils";
import store from "../../store";
import Vue from "vue";

export default {
	install() {
		/**
     * Returns a boolean depending on user rights on provided authorization
     * @param {string} authorizationString can be "globalAction.showHomeBtn"
     * @param {object} additionalPayload in case more data is required, i.e for a userCampaign check
     * @returns 
     */
		Vue.prototype.$hasRight = function (authorizationString, additionalPayload = {}) {
			const authParts = authorizationString.split(".");
     
			let foundAuthorization = authorization.authorizations;
			authParts.forEach(part => {
				foundAuthorization = foundAuthorization[part];
			});

			if (!foundAuthorization) {
				console.error("authorization not found :", authorizationString);
			}
			
			return authorization.hasRight({...store.state.user.current, ...additionalPayload}, foundAuthorization);
		};

		/**
     * return true if the given role is above user roles in terms of roles hierarchy
     * @param {string} role the role to check
     * @returns bool
     */
		Vue.prototype.$isRoleAbove = function (role) {
			return authorization.isRoleAbove(store.state.user.current.roles, role);
		};
	},
};
