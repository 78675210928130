import Vue from "vue";

// Slot buttons
import HighButtonSlot from "../components/slots/buttons/High.button.slot.vue";
import MediumButtonSlot from "../components/slots/buttons/Medium.button.slot";
import LowButtonSlot from "../components/slots/buttons/Low.button.slot";

Vue.component("HighButtonSlot", HighButtonSlot);
Vue.component("MediumButtonSlot", MediumButtonSlot);
Vue.component("LowButtonSlot", LowButtonSlot);

import Modale from "../components/slots/modal/Modal.slot"
Vue.component("Modale", Modale);

// Loader
import Loader from "../components/Loader.vue";
Vue.component("Loader", Loader);

// Snackbar
import SystemSnackbar from "../components/system/messages/SnackbarContainer.vue";
Vue.component("SystemSnackbar", SystemSnackbar);

// Fields
import EditableListField from "../components/slots/field/EditableList.field.vue";
Vue.component("editable-list-field", EditableListField);

//new el
import ButtonSlot from "../components/slots/buttons/Button.slot";
import HeaderSlot from "../components/slots/Header.slot";
import WaitingSlot from "../components/slots/Waiting.slot";

Vue.component("ButtonSlot", ButtonSlot);
Vue.component("HeaderSlot", HeaderSlot);
Vue.component("WaitingSlot", WaitingSlot);
    