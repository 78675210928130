<!--<template>
  <v-dialog v-model="show" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="text-h5">Support</span>
      </v-card-title>
      <v-card-text class="pb-0">
        <v-container>
          <div v-if="ticketSent === false">
            <p>
              Vous avez une idée, une suggestion, une remontée de bug à nous
              faire parvenir ?
            </p>
            <p>
              Vous pouvez ouvrir un ticket avec le formulaire ci-dessous, vous
              recevrez les détails de ce dernier ainsi qu'une réponse par mail.
            </p>
          </div>

          <v-form
            method="post"
            ref="form"
            @submit.prevent="createTicket()"
            v-if="ticketSent === false"
          >
            <v-text-field
              outlined
              type="text"
              :rules="[$rules.required]"
              :loading="loading"
              :disabled="loading"
              placeholder="Objet"
              v-model="form.subject"
            />
            <v-textarea
              auto-grow
              outlined
              type="text"
              :rules="[$rules.required]"
              :loading="loading"
              :disabled="loading"
              placeholder="Votre question"
              v-model="form.message"
            />
            <v-file-input
              label="Joindre un fichier"
              outlined
              clearable
              :loading="loading"
              :disabled="loading"
              dense
              v-model="form.file"
            ></v-file-input>

            <HighButtonSlot type="submit" class="float-right mt-2">
              Envoyer
            </HighButtonSlot>
          </v-form>

          <div v-else class="text-center">
            <v-icon class="icon__check mb-5">mdi-check</v-icon>
            <h2 class="mb-5">Ticket envoyé à notre support</h2>
            <p class="mb-5">
              Vous recevrez très prochainement un récapitulatif par mail.
            </p>
          </div>

          <v-card-actions class="pb-4">
            <MediumButtonSlot @click="hideModal"> Fermer </MediumButtonSlot>
          </v-card-actions>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>-->

<template>
  <v-dialog v-model="show" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="text-h5">Support</span>
      </v-card-title>
      <v-card-text class="pb-0">
        <v-container>
          <div>
            <p>
              Vous avez une remontée de bug à nous faire parvenir ? Envoyez un
              mail à l'adresse suivante :
              <a target="_blank" href="mailto:support@maorie.com"
                >support@maorie.com</a
              >.
            </p>
            <p>Nous vous recontacterons très rapidement !</p>
          </div>
        </v-container>
      </v-card-text>
      <v-card-actions class="justify-center pb-4">
        <HighButtonSlot @click="hideModal"> Ok </HighButtonSlot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "SupportModal",
  data() {
    return {
      show: false,
      form: {
        subject: "",
        message: "",
        file: null
      },
      ticketSent: false,
      loading: false
    };
  },
  methods: {
    showModal() {
      this.show = true;
    },
    hideModal() {
      this.show = false;
    },
    async createTicket() {
      if (this.$refs.form.validate() === false) {
        return;
      }

      this.loading = true;

      let formData = new FormData();
      formData.append("message", this.form.message);
      formData.append("subject", this.form.subject);
      formData.append("file", this.form.file);

      await this.$api.support.createTicket(formData).then(() => {
        this.ticketSent = true;
        this.loading = false;
      });
    }
  },
  mounted() {
    this.$root.$on("closeCurrent", this.hideModal);
  }
};
</script>

<style scoped>
.icon__check {
  font-size: 70px;
}
</style>
