export default {
  getErrorText: status => {
    let text = " : ";
    switch (status) {
      case 500:
        text += "Une erreur est survenue";
        break;
      case 400:
        text += "Informations manquantes ou erronées";
        break;
      case 404:
        text += "Un des éléments n'a pas pu être retrouvé";
        break;
      case 401:
      case 403:
        text += "Permission non accordée";
        break;
      default:
        text += "Un problème est survenu";
        break;
    }

    text += ".";
    return text;
  }
};
