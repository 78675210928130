// initial state
const state = () => ({
  all: []
});

// getters
const getters = {};

// mutations
const mutations = {
  addCore(state, { core, coreIdIndex }) {
    state.all[coreIdIndex] = core;
  },
  resetAllCores(state) {
    state.all = [];
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations
};
