import {api} from "../../api/index";

export default {
	namespaced: true,
	strict: false,
	state: {
		groundSurvey: false,
		isDisplayedRT: false
	},

	mutations: {
		SET_GROUND_SURVEY(state, groundSurvey){
			state.groundSurvey = groundSurvey;
		},
		SET_IS_DISPLAYED(state, isDisplayedRT){
			state.isDisplayedRT = isDisplayedRT;
		}
	},

	getters: {
		groundSurvey(state){
			return state.groundSurvey;
		},
		isDisplayedRT(state){
			return state.isDisplayedRT;
		}
	},

	actions: {
		async initStore({dispatch}, userId){
			await Promise.all([dispatch("setGroundSurvey", userId)]);
		},

		purgeStore({commit}){
			commit("SET_GROUND_SURVEY", false);
		},

		async setGroundSurvey({commit}, userId){
			let result = await api.auscultationCampaigns.getGroundSurvey(userId);
			commit("SET_GROUND_SURVEY", result);
			return result;
		},

		setIsDisplayed({commit, state}){
			commit("SET_IS_DISPLAYED", !state.isDisplayedRT);
		}
	}
};
