import {api} from "../../api";

export default {
	namespaced: true,
	strict: false,

	state: {
		organizations: false,
		filterName: false,
		filterName: false,
		filterType: null
	},

	mutations: {
		SET_ORGANIZATIONS(state, organizations){
			state.organizations = organizations;
		},
		SET_FILTERNAME(state, value){
			if(value === "" || value === null)state.filterName = false;
			else state.filterName = value;
		},
		SET_FILTERTYPE(state, value){
			if(value === "" || value === null)state.filterType = null;
			else state.filterType = value;
		}
	},

	getters: {
		organizations(state){
			return state.organizations;
		},
		filterOrganizations(state){
			let organizations = state.organizations;
			if(state.filterName !== false)organizations = organizations.filter(value => value.name.toLowerCase().indexOf(state.filterName.toLowerCase()) > -1);
			if(state.filterType !== null)organizations = organizations.filter(value => value.isInternal === state.filterType);
			return organizations;
		},

		filterName(state){
			return state.filterName || "";
		},

		filterType(state){
			return state.filterParent || "";
		}
	},

	actions: {
		async initStore({dispatch}){
			await dispatch("user/waitLoadUser", undefined, {root: true});
			await Promise.all([dispatch("updateOrganizations")]);
		},

		purgeStore({commit}){
			commit("SET_ORGANIZATIONS", false);
		},

		async updateOrganizations({commit}){
			commit("SET_ORGANIZATIONS", await api.organizations.findAll(null, true));
		}
	}
};
