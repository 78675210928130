import Popup from "../components/slots/Popup.slot.vue";

export default function createPopup(el, childProps = {}){
	let popup = new Popup({
		propsData: {
			child: el,
			childProps: childProps
		}
	});

	let div = document.createElement("div");
	document.getElementById("popup").append(div);
	
	popup.$mount(div);
	popup.emit = () => {};

	return {
		close(){
			popup.$el.remove();
			popup.$destroy();
		},
		listen(fnc){
			popup.emit = fnc;
		},
		get el(){
			return popup.el;
		}
	};
}
