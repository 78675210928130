// initial state
const state = () => ({
  files: 0,
  livreries: 0
});

// getters
const getters = {};

// mutations
const mutations = {
  updateFilesCount(state, count) {
    state.files = count;
  },
  updateLivreriesCount(state, count) {
    state.livreries = count;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations
};
