<template>
  <v-tooltip bottom v-if="typeof tooltip === 'string'">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="MediumButtonSlot"
        @click="clicked($event)"
        v-bind="attrs"
        v-on="on"
        :to="_to"
        :disabled="_disabled"
        :type="_type"
        :loading="_loading"
      >
        <slot></slot>
      </v-btn>
    </template>
    <span>{{ tooltip }}</span>
  </v-tooltip>

  <v-btn
    v-else
    class="MediumButtonSlot"
    @click="clicked($event)"
    :to="_to"
    :disabled="_disabled"
    :type="_type"
    :loading="_loading"
  >
    <slot></slot>
  </v-btn>
</template>

<script>
export default {
  name: "MediumButtonSlot",
  props: ["_to", "_disabled", "_type", "tooltip", "_loading"],
  methods: {
    clicked($event) {
      this.$emit("click", $event);
    }
  }
};
</script>

<style lang="scss" scoped>
.MediumButtonSlot {
  box-shadow: none;
  background-color: grey;
}
</style>
