import axiosPlugin from "../../plugins/axios";
import toastPlugin from "../../plugins/messages";
const axios = axiosPlugin._axios;
const toast = toastPlugin.toast;
const getMessage = toastPlugin.getMessage;

const findByCoringCampaignId = async coringCampaignId => {
  try {
    const response = await axios.get(
      `coring-campaigns/${coringCampaignId}/display-name-prefixes`,
      {
        showLoader: false
      }
    );
    return response.data;
  } catch (error) {
    toast(
      "error",
      "Impossible de récupérer les préfixes" + getMessage(error.response.status)
    );
    throw error;
  }
};

const create = async displayNamePrefix => {
  try {
    const response = await axios.post(
      "/display-name-prefixes",
      {
        ...displayNamePrefix,
      },
      {
        showLoader: false
      }
    );
    return response.data;
  } catch (error) {
    let toastMessage = "Impossible de créer le préfixe" + getMessage(error.response.status);

    if (403 === error.response.data.code) {
      toastMessage = "Ce préfixe existe déjà."
    }

    toast(
      "error",
      toastMessage
    );
    throw error;
  }
};

const deletePrefix = async displayNamePrefixId => {
  try {
    const response = await axios.delete(`/display-name-prefixes/${displayNamePrefixId}`);
    toast("success", "Préfixe supprimé !");
    return response.data;
  } catch (error) {
    toast(
      "error",
      "Impossible de supprimer le préfixe" + getMessage(error.response.status)
    );
    throw error;
  }
};

export default {
  findByCoringCampaignId,
  create,
  deletePrefix
};
