<template>
  <v-snackbar
    v-model="show"
    :timeout="message.actionText && message.actionText.length ? 20000 : timeout"
  >
    <v-icon class="message-icon" :color="message.color">{{
      handleIcon(message.color)
    }}</v-icon>
    <span :style="'color: ' + message.color"
      >{{ message.text }}
      <span v-show="!message.text"
        >Quelque chose s'est mal passé, veuillez contacter le support en
        envoyant un mail et si possible une capture d'écran à
        <a href="mailto:support@maorie.com">support@maorie.com</a>. Merci
        !</span
      >
    </span>
    <template v-slot:action="{ attrs }">
      <div>
        <v-btn color="white" text v-bind="attrs" @click="closeSnackbar">
          fermer
        </v-btn>
        <v-btn
          v-if="message.actionText && message.actionText.length"
          color="success"
          text
          v-bind="attrs"
          @click="execAction()"
        >
          {{ message.actionText }}
        </v-btn>
      </div>
    </template></v-snackbar
  >
</template>

<script>
export default {
  props: ["message", "timeout"],
  data() {
    return {
      show: this.message.show,
    };
  },
  methods: {
    execAction() {
      this.message.confirmCallback()
      this.closeSnackbar()
    },
    handleIcon(color) {
      let icon = "mdi-";
      switch (color) {
        case "success":
          icon += "check";
          break;
        case "error":
          icon += "close";
          break;
        case "info":
          icon += "information";
          break;
        case "warning":
          this.color = "yellow";
          icon += "alert";
          break;
        case "yellow":
          icon += "alert";
          break;
        default:
          return "";
      }

      return icon;
    },
    closeSnackbar() {
      this.$emit("closeSnackbar");
    },
  },
  watch: {
    show() {
      if (!this.show) {
        this.closeSnackbar();
      }
    },
  },
  mounted() {
    setTimeout(this.closeSnackbar, this.timeout);
  },
};
</script>

<style lang="scss" scoped>
.message-icon {
  margin-right: 10px;
}
</style>
