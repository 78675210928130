import {api} from "../../api";

export default {
	namespaced: true,
	strict: false,

	state: {
		project: false,
		campaigns: false,

		filterName: false,
		filterType: false,
		filterStatus: false,
		filterArchived: false
	},

	mutations: {
		SET_PROJECT(state, project){
			state.project = project;
		},
		SET_CAMPAIGNS(state, campaigns){
			state.campaigns = campaigns;
		},
		SET_FILTERNAME(state, value){
			if(value === "" || value === null)state.filterName = false;
			else state.filterName = value;
		},
		SET_FILTERTYPE(state, value){
			state.filterStatus = false;
			if(value === "" || value === null)state.filterType = false;
			else state.filterType = value;
		},
		SET_FILTERSTATUS(state, value){
			if(value === null || value.length === 0)state.filterStatus = false;
			else state.filterStatus = value;
		},
		SET_FILTERARCHIVED(state){
			state.filterArchived = !state.filterArchived;
		},
		UPDATE_CAMPAIGN(state, campaign){
			let index = state.campaigns.findIndex(c => c.id === campaign.id);
			state.campaigns[index] = campaign;
			state.campaigns = [...state.campaigns];
		}
	},

	getters: {
		project(state){
			return state.project;
		},
		campaigns(state){
			return state.campaigns;
		},
		filterCampaigns(state){
			let campaigns = state.campaigns;
			if(state.filterName !== false)campaigns = campaigns.filter(value => value.name.toLowerCase().indexOf(state.filterName.toLowerCase()) > -1);
			if(state.filterType !== false)campaigns = campaigns.filter(value => value.type === state.filterType);
			if(state.filterStatus !== false)campaigns = campaigns.filter(value => state.filterStatus.indexOf(value.status) > -1);
			if(state.filterArchived === false)campaigns = campaigns.filter(value => value.archived === state.filterArchived);
			return campaigns;
		},

		filterName(state){
			return state.filterName || "";
		},
		filterType(state){
			return state.filterType || "";
		},
		filterStatus(state){
			return state.filterStatus || [];
		},
		filterArchived(state){
			return state.filterArchived;
		}
	},

	actions: {
		async initStore({commit}, id){
			commit("SET_PROJECT", await api.workspaces.findById(id));
			commit("SET_CAMPAIGNS", await api.campaigns.findAllFromWorkspace(id, {}, true));
		},

		purgeStore({commit}){
			commit("SET_PROJECT", false);
			commit("SET_CAMPAIGNS", false);
		},

		async updateCampaigns({commit}, id){
			commit("SET_CAMPAIGNS", await api.campaigns.findAllFromWorkspace(id, {}, true));
		},

		async updateCampaign({commit}, id){
			commit("UPDATE_CAMPAIGN", await api.campaigns.findById(id));
		}
	}
};
