import axiosPlugin from "../plugins/axios";
import toastPlugin from "../plugins/messages";
const axios = axiosPlugin._axios;
const toast = toastPlugin.toast;
const getMessage = toastPlugin.getMessage;

const createTicket = async formData => {
  try {
    const response = await axios.post(`/request-support`, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
    return response.data;
  } catch (error) {
    toast(
      "error",
      "Impossible de créer le ticket" + getMessage(error.response.status)
    );
    throw error;
  }
};

export default {
  createTicket
};
