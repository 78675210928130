const infoStatusCore = Object.freeze({
	empty: {
		label: "A saisir",
		color: "red",
		icon: "mdi-format-align-bottom",
	},
	waiting: {
		label: "En attente",
		color: "green",
		icon: "mdi-alarm",
	},
	extracted: {
		label: "Extraite",
		color: "green",
		icon: "mdi-check"
	},
	sent_to_study_manager: {
		label: "Envoyée au chargé d'affaires",
		color: "orange",
		icon: "mdi-check",
	},
	validated: {
		label: "Validée",
		color: "green",
		icon: "mdi-check",
	},
	sent_to_provider: {
		label: "Envoyée au laboratoire",
		color: "green",
		icon: "mdi-check",
	},
	results_acquired: {
		label: "Résultats reçus",
		color: "green",
		icon: "mdi-check",
	},
	cancelled: {
		label: "Annulée",
		color: "red",
		icon: "mdi-close",
	},
});

export const infoStatusCoringCA = Object.freeze({
	awaiting_validation: {
		label: "Préparation",
		step: 1,
	},
	programing_validated: {
		label: "Prélèvement",
		step: 2,
	},
	taken: {
		label: "Traitement",
		step: 3,
	},
	taking_validated: {
		label: "Essais",
		step: 4,
	},
	sent_for_analysis: {
		label: "Expertise",
		step: 5,
	},
	results_obtained: {
		label: "Finalisation",
		step: 6,
	},
	status_archived: {
		label: "Archivé",
		step: 7,
	},
});

export const infoStatusCoringCS = Object.freeze({
	awaiting_validation: {
		label: "Préparation",
		step: 1,
	},
	programing_validated: {
		label: "Prélèvement",
		step: 2,
	},
	taken: {
		label: "Traitement",
		step: 3,
	},
	results_obtained: {
		label: "Finalisation",
		step: 4,
	},
	status_archived: {
		label: "Archivé",
		step: 5,
	},
});

export default Object.freeze({
	getInfoStatusCore(core){
		return infoStatusCore[core.status] || false;
	},
	clearCore: {
		NR_name: "",
		abscissa: null,
		campaignId: "",
		diameter: null,
		direction: null,
		displayName: "",
		displayNamePrefix: null,
		isFullStructure: null,
		landmark: null,
		lane: null,
		lat: null,
		lateralPosition: "",
		lng: null,
		note: "",
		number: null,
		roadId: null,
		structureType: null,
		status: "empty"
	},
	getInfoStatusCampaign(core){
		return infoStatusCoringCA[core.status] || false;
	},
});
