import axiosPlugin from "../../plugins/axios";
import toastPlugin from "../../plugins/messages";
const axios = axiosPlugin._axios;
const toast = toastPlugin.toast;
const getMessage = toastPlugin.getMessage;

const findAll = async () => {
  try {
    const response = await axios.get("/agencies", { showLoader: false });
    return response.data;
  } catch (error) {
    toast(
      "error",
      "Impossible de récupérer les agences" + getMessage(error.response.status)
    );
    throw error;
  }
};
const findByOrganization = async () => {
  try {
    const response = await axios.get(`/agencies?byOrganization=true`);
    return response.data;
  } catch (error) {
    toast(
      "error",
      "Impossible de récupérer les agences" + getMessage(error.response.status)
    );
    throw error;
  }
};

export default {
  findAll,
  findByOrganization
};
