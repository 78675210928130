import axiosPlugin from "../plugins/axios";
import toastPlugin from "../plugins/messages";
import router from "../router";
const axios = axiosPlugin._axios;
const toast = toastPlugin.toast;
const getMessage = toastPlugin.getMessage;

const findById = async id => {
	try {
		const response = await axios.get(`/workspaces/${id}`);

		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de récupérer le projet" + getMessage(error.response.status)
		);
		throw error;
	}
};

const findByUser = async(userId, params) => {
	try {
		const response = await axios.get(`/users/${userId}/workspaces?displayCampaigns=true`, {params});

		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de récupérer les projets" + getMessage(error.response.status)
		);
		throw error;
	}
};

const editArchived = async(workspaceId, isArchived) => {
	const statusText = isArchived ? "archivé" : "désarchivé";
	try {
		const response = await axios.patch(`/workspaces/${workspaceId}`, {
			isArchived
		});

		toast("success", "Projet " + statusText);

		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Le projet n'a pas pu être " +
        statusText +
        getMessage(error.response.status)
		);
		throw error;
	}
};

const edit = async(id, workspace) => {
	try {
		const response = await axios.put(`/workspaces/${id}`, workspace);

		toast("success", "Projet modifié");

		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de modifier le projet: " +
        router.app.$t(error.response.data.message.split("ERROR:")[1].trim())
		);
		throw error;
	}
};

const create = async workspace => {
	try {
		const response = await axios.post("/workspaces", workspace);

		toast("success", "Projet créé");

		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de créer le projet : " +
        router.app.$t(error.response.data.message.split("ERROR:")[1].trim())
		);
		throw error;
	}
};

const deleteWorkspace = async workspaceId => {
	try {
		const response = await axios.delete(`/workspaces/${workspaceId}`);
	
		toast("success", "Projet supprimé");
	
		return response.data;
	  }
	catch (error){
		toast(
		  "error",
		  "Impossible de supprimer le projet " + getMessage(error.response)
		);
		throw error;
	  }
};

export default {
	findById,
	findByUser,
	editArchived,
	create,
	edit,
	deleteWorkspace
};
