import axiosPlugin from "../plugins/axios";
import toastPlugin from "../plugins/messages";
const axios = axiosPlugin._axios;
const toast = toastPlugin.toast;
const getMessage = toastPlugin.getMessage;

const findUserNotifications = async (
  page = 1,
  itemsCount = 10,
  unread = null
) => {
  try {
    let url = `/notifications?page=${page}&itemsCount=${itemsCount}`;

    if (null !== unread) url += "&filterByReadState=" + unread;

    const response = await axios.get(url, {
      showLoader: false,
    });
    return response.data;
  } catch (error) {
    console.error(error)
    throw error;
  }
};

const readAllNotifications = async () => {
  try {
    await axios.patch('/notifications', { isRead: true });
  } catch (error) {
    toast(
        "error",
        "Impossible de marquer les notifications comme lues" + getMessage(error.response.status)
    );
    throw error;
  }
};

const findById = async (id) => {
  try {
    const response = await axios.get(`/notifications/${id}`);
    return response.data;
  } catch (error) {
    toast(
      "error",
      "Impossible de récupérer le contenu de la notification" +
        getMessage(error.response.status)
    );
    throw error;
  }
};

const readNotification = async (id) => {
  try {
    await axios.patch(`/notifications/${id}`, { isRead: true });
  } catch (error) {
    toast(
      "error",
      "Impossible de lire la notification" + getMessage(error.response.status)
    );
    throw error;
  }
};

const findCampaignByNotifiableId = async (id) => {
    try {
      const response = await axios.get(`/notifiables/campaigns/${id}`);
      return response.data;
    } catch (error) {
      toast(
        "error",
        "Impossible de récupérer le contenu de la notification" +
          getMessage(error.response.status)
      );
      throw error;
    }
  };

  const findDocumentByNotifiableId = async (id) => {
    try {
      const response = await axios.get(`/notifiables/documents/${id}`);
      return response.data;
    } catch (error) {
      toast(
        "error",
        "Impossible de récupérer le contenu de la notification" +
          getMessage(error.response.status)
      );
      throw error;
    }
  };

  const findRequiredDocumentByNotifiableId = async (id) => {
    try {
      const response = await axios.get(`/notifiables/required-documents/${id}`);
      return response.data;
    } catch (error) {
      toast(
        "error",
        "Impossible de récupérer le contenu de la notification" +
          getMessage(error.response.status)
      );
      throw error;
    }
  };

export default {
  findUserNotifications,
  readAllNotifications,
  findById,
  readNotification,

  findCampaignByNotifiableId,
  findDocumentByNotifiableId,
  findRequiredDocumentByNotifiableId
};
