import axiosPlugin from "../../plugins/axios";
import toastPlugin from "../../plugins/messages";
import router from "../../router";
const axios = axiosPlugin._axios;
const toast = toastPlugin.toast;
const getMessage = toastPlugin.getMessage;

const findById = async id => {
	try {
		const response = await axios.get(`/auscultation-campaigns/${id}`);
		return response.data;
	}
	catch (error){
		throw error;
	}
};

const create = async campaign => {
	try {
		const response = await axios.post("/auscultation-campaigns", campaign);

		toast("success", "Campagne créée !");

		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de créer la campagne : " +
			router.app.$t(error.response.data.message.split("ERROR:")[1].trim())
		);
		throw error;
	}
};

const edit = async(id, campaign) => {
	try {
		const response = await axios.put(`/auscultation-campaigns/${id}`, campaign);

		toast("success", "Campagne modifiée !");

		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de modifier la campagne" + getMessage(error.response.status)
		);
		throw error;
	}
};

const editStatus = async(campaignId, status) => {
	try {
		const response = await axios.patch(
			`/auscultation-campaigns/${campaignId}`,
			{status}
		);

		toast("success", "Statut modifié !");

		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de modifier le statut" + getMessage(error.response.status)
		);
		throw error;
	}
};

const getCharts = async(id, category) => {
	try {
		const response = await axios.get(`/campaigns/${id}/charts/${category}`);
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de récupérer les données de la campagne" +
			getMessage(error.response.status)
		);
		throw error;
	}
};

const getChartData = async(id, filterList) => {
	let filterString = "";
	filterList.forEach((filter, index) => {
		if(filter.currentValue === null || filter.currentValue === undefined){
			filter.currentValue = "";
		}
		index > 0
			? (filterString += "&" + filter.columnName + "=" + filter.currentValue)
			: (filterString += "?" + filter.columnName + "=" + filter.currentValue);
	});

	try {
		const response = await axios.get(
			`/charts/${id}/generate-content${filterString}`,
			{
				showLoader: false
			}
		);
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de récupérer les données du graphique " + id + ". " +
			getMessage(error.response.status)
		);
		throw error;
	}
};

const getStatisticTables = async(id, category) => {
	try {
		const response = await axios.get(
			`/auscultation-campaigns/${id}/statistic-tables` +
			(null !== category ? "/" + category : ""),
			{
				showLoader: false
			}
		);
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de récupérer les tableaux des statistiques de la campagne" +
			getMessage(error.response.status)
		);
		throw error;
	}
};

const getStatisticTableData = async(id, filters) => {
	let filterString = "";
	filters.forEach((filter, index) => {
		if(filter.currentValue === null || filter.currentValue === undefined){
			filter.currentValue = "";
		}
		index > 0
			? (filterString += "&" + filter.columnName + "=" + filter.currentValue)
			: (filterString += "?" + filter.columnName + "=" + filter.currentValue);
	});

	try {
		const response = await axios.get(
			`/statistic-tables/${id}/generate-content${filterString}`,
			{
				showLoader: false
			}
		);
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de récupérer les tableaux des statistiques de la campagne" +
			getMessage(error.response.status)
		);
		throw error;
	}
};

const getCategories = async(id, hasChart = false, hasCarto = false) => {
	try {
		let parameters = `has_chart=${hasChart.toString()}&has_carto=${hasCarto.toString()}`;
		const response = await axios.get(
			`/campaigns/${id}/categories?${parameters}`
		);
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de récupérer les catégories de la campagne" +
			getMessage(error.response.status)
		);
		throw error;
	}
};

const getGroundSurvey = async(userId) => {
	try {
		const response = await axios.get(`/users/${userId}/campaigns?isGroundSurvey=true`);
		return response.data;
	}
	catch (error){
		toast(
			"error",
			"Impossible de récupérer les catégories de la campagne" +
			getMessage(error.response.status)
		);
		throw error;
	}
};

export default {
	findById,
	create,
	edit,
	editStatus,
	getChartData,
	getStatisticTables,
	getStatisticTableData,
	getCategories,
	getCharts,
	getGroundSurvey
};
