// initial state
const state = () => ({
  current: {},
});

// getters
const getters = {};

// mutations
const mutations = {
  set_current(state, campaign) {
    for (const [key, value] of Object.entries(campaign)) {
      state.current[key] = value;
    }
  },
  reset_current(state) {
    state.current = {};
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
