<template>
  <div class="singleNotification" @click="openNotification">
    <div v-if="false === notification.isRead" class="readDot">&nbsp;</div>
    <div class="singleNotification__text">
      <p :style="notification.isRead ? 'color: gray;' : ''" class="text">
        {{ notification.text }}
      </p>
      <p class="date">
        {{
          moment(notification.createdAt, "YYYY-MM-DDTHH:mm:ss+Z").format(
            "Do MMMM YYYY, HH:mm"
          )
        }}
      </p>
    </div>
    <div
      :style="notification.isRead ? 'opacity: .6;' : ''"
      class="singleNotification__icon"
    >
      <v-icon color="#290703">{{ icon }}</v-icon>
    </div>
  </div>
</template>

<script>
export default {
  props: ["notification"],
  computed: {
    icon() {
      let iconName = "";
      switch (this.notification.notifiableType) {
        case "campaigns":
          iconName = "archive-star-outline";
          break;
        case "documents":
          iconName = "file-outline";
          break;
        case "required_documents":
          iconName = "file-find-outline";
          break;

        default:
          iconName = "close-octagon";
          break;
      }

      return "mdi-" + iconName;
    },
  },
  methods: {
    async openNotification() {
      const notification = this.notification;
      // get notifiable
      let notifiable = {};

      switch (notification.notifiableType) {
        case "campaigns":
          notifiable = await this.$api.notifications.findCampaignByNotifiableId(
            notification.notifiableId
          );

          if (notifiable.type === "coring")
            this.$router.push({
              name: "coring-campaign-dashboard",
              params: { id: notifiable.id },
            });
          else
            this.$router.push({
              name: "auscultation-campaign-dashboard",
              params: { id: notifiable.id },
            });
          break;
        case "documents":
          notifiable = await this.$api.notifications.findDocumentByNotifiableId(
            notification.notifiableId
          );
          if ("new_document" === notification.notifiableType)
            this.$router.push({
              name: "campaign-files",
              params: { id: notifiable.campaign.id },
            });
          else
            this.$router.push({
              name: "campaign-deliverables",
              params: { id: notifiable.campaign.id },
            });
          break;
        case "required_documents":
          notifiable =
            await this.$api.notifications.findRequiredDocumentByNotifiableId(
              notification.notifiableId
            );
          this.$router.push({
            name: "campaign-files",
            params: { id: notifiable.campaign.id },
          });
          break;

        default:
          break;
      }
      this.$api.notifications.readNotification(notification.id);
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.singleNotification {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  border-radius: 4px 4px 0 0;
  margin: 0 16px;
  min-height: 80px;
  border-bottom: 1px solid rgb(187, 187, 187);
  cursor: pointer;

  transition: background-color 0.2s ease-in;
  &:hover {
    background-color: rgb(230, 230, 230);
  }

  .readDot {
    position: absolute;
    left: 2px;
    top: 15px;
    background-color: #290703;
    height: 8px;
    width: 8px;
    border-radius: 4px;
  }

  &__text {
    display: flex;
    flex-direction: column;
    width: 85%;

    p {
      margin-bottom: 0;

      &.text {
        font-size: 14px;
      }
      &.date {
        font-size: 12px;
        color: gray;
      }
    }
  }

  &__icon {
    height: 50px;
    width: 50px;
    border-radius: 25px;
    background-color: #29070339;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
