export default {
	namespaced: true,
	strict: false,

	state: {
		current: false
	},
	getters: {
		user(state){
			return state.current || {};
		},
		userIsLoaded(state){
			return !!state.current;
		}
	},
	mutations: {
		setUser(state, user){
			state.current = user;
		}
	},
	actions: {

		async waitLoadUser({state}, time = 100){
			if(state.current === false) await new Promise((resolve, reject) => {
				const interval = setInterval(() => {
					if(state.current !== false){
						clearInterval(interval);
						resolve();
					}
				}, time);
			});
		}
	}
};
