<template>
	<div
	button-popup-slot
	>
		<h1 v-if="title">
			{{ title }}
		</h1>

		<i v-if="subTitle">
			{{ subTitle }}
		</i>

		<div>
			<ButtonSlot
			@click="emit(true)"
			>
				oui
			</ButtonSlot>

			<ButtonSlot
			@click="emit(false)"
			_theme="light-gray"
			>
				non
			</ButtonSlot>
		</div>
	</div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
	props: {
		title: {
			default: ""
		},
		subTitle: {
			default: ""
		}
	}
});
</script>

<style lang="scss">
div[button-popup-slot]{
	user-select: none;
	padding: 20px;
	background: #fff;
	display: flex;
	flex-direction: column;
	gap: 20px;
	border-radius: 4px;

	> h1{
		text-align: center;
	}

	> i{
		white-space: pre-wrap;
		text-align: center;
	}

	> div{
		display: flex;
		justify-content: space-around;
		flex-direction: row-reverse;
		gap: 10px;
	}
}
</style>
