// initial state
const state = () => ({
    coresPictures: [],
  });
  
  // getters
  const getters = {
  };
  
  // mutations
  const mutations = {
    storePictures(state, newCore) {
      // si la carotte n'existe pas encore dans le state, la push dedans
      state.coresPictures[newCore.id] = newCore.pictures;
    }
  };
  
  export default {
    namespaced: true,
    state,
    getters,
    mutations
  };
  