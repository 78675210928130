import axiosPlugin from "../plugins/axios";
import toastPlugin from "../plugins/messages";
const axios = axiosPlugin._axios;
const toast = toastPlugin.toast;
const getMessage = toastPlugin.getMessage;

const getCoringCampaignsStats = async (agencyId, siteId) => {
  try {
    let filter = "";
    if (agencyId !== null && siteId !== null) {
      filter = `?agencyId=` + agencyId + `&siteId=` + siteId;
    } else if (agencyId !== null) {
      filter = `?agencyId=` + agencyId;
    } else if (siteId !== null) {
      filter = `?siteId=` + siteId;
    }
    const response = await axios.get(`/stats/coring-campaigns` + filter);
    return response.data;
  } catch (error) {
    toast(
      "error",
      "Impossible de récupérer les statistiques" +
        getMessage(error.response.status)
    );
    throw error;
  }
};

const getCoringCampaignsStatsV2 = async (agencyId, siteId) => {
  try {
    let filter = "";
    if (agencyId !== null && siteId !== null) {
      filter = `?agencyId=` + agencyId + `&siteId=` + siteId;
    } else if (agencyId !== null) {
      filter = `?agencyId=` + agencyId;
    } else if (siteId !== null) {
      filter = `?siteId=` + siteId;
    }
    const response = await axios.get(`/stats/coring-campaigns/v2` + filter);
    return response.data;
  } catch (error) {
    toast(
      "error",
      "Impossible de récupérer les statistiques" +
        getMessage(error.response.status)
    );
    throw error;
  }
};

export default { getCoringCampaignsStats, getCoringCampaignsStatsV2 };
