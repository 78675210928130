<template>
  <div>
    <v-overlay v-if="loading" :opacity="0.5">
      <v-progress-circular
        indeterminate
        :size="size"
        :color="color"
        :width="width"
      >
        Chargement...
      </v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Loader",
  computed: {
    ...mapState("loader", ["loading"]),
  },
  props: {
    color: {
      type: String,
      default: "primary",
    },
    size: {
      type: Number,
      default: 64,
    },
    width: {
      type: Number,
      default: 1,
    },
  },
};
</script>

<style scoped>
.v-progress-circular {
  margin: 1rem;
}
</style>
