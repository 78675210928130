<template>
	<div
	:class="errorBucket.length ? 'fieldContainer hasError' : 'fieldContainer'"
	>
		<p :class="itemsList.length > 0 ? 'label active' : 'label'">
			{{ label }}
		</p>

		<div class="inputControl">
			<div class="itemsList">
				<v-chip
				class="providerChip"
				v-for="listItem in itemsList"
				:key="listItem.provider.id"
				small
				>
					<p @click="edit(listItem)">
						{{ itemText(listItem) }}
					</p>

					<v-icon
					class="closeIcon"
					@click="removeChip(listItem)"
					>
						mdi-close
					</v-icon>
				</v-chip>
			</div>

			<div class="action">
				<medium-button-slot @click="openModale">
					<v-icon>mdi-plus</v-icon>
				</medium-button-slot>
			</div>
		</div>

		<VMessages
		class="errorMessages"
		:value="errorBucket"
		color="error"
		/>

		<Modal
		v-if="isModalOpen"
		:title="modaleTitle"
		@close="isModalOpen = false"
		@validate="addElem()"
		>
			<slot
			name="modale"
			:newItem="newItem"
			/>
		</Modal>
	</div>
</template>

<script>
import Modal from "@/components/slots/modal/Modal.slot.vue";
import VInput from "vuetify/lib/components/VInput/VInput.js";

export default {
	components: {
		Modal
	},
	props: ["label", "value", "emptyItem", "modaleTitle", "itemText"],
	extends: VInput,
	data() {
		return {
			itemsList: this.value,
			isModalOpen: false,
			newItem: JSON.parse(JSON.stringify(this.emptyItem)),
			editMode: {
				id: null,
				active: false
			}
		};
	},
	watch: {
		value() {
			this.itemsList = this.value;
		}
	},
	methods: {
		edit(ap) {
			this.editMode = {
				id: ap.provider.id,
				active: true
			};
			this.newItem = ap;
			this.openModale();
		},
		emitChange() {
			this.$emit("input", this.itemsList);
		},
		openModale() {
			this.isModalOpen = true;
		},
		addElem() {
			const elem = JSON.parse(JSON.stringify(this.newItem));
			if (false === this.editMode.active) this.itemsList.push(elem);
			this.isModalOpen = false;
			this.newItem = JSON.parse(JSON.stringify(this.emptyItem));
			this.editMode.active = false;
		},
		removeChip(listItem) {
			const index = this.itemsList.findIndex(ap => ap.id === listItem.id);

			if (-1 === index) return;

			this.itemsList.splice(index, 1);
			this.emitChange();
		}
	}
};
</script>

<style lang="scss" scoped>
.fieldContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  position: relative;

  .errorMessages {
    padding: 0 12px;
  }

  .label.active {
    position: absolute;
    background-color: white;
    font-size: 16px;
    line-height: 20px;
    height: 20px;
    top: -10px;
    transform: scale(0.75);
    left: -2px;
    padding: 0 3px;
  }

  .label {
    position: absolute;
    top: 20px;
    color: rgba(0, 0, 0, 0.6);
    left: 12px;
    transform: translateY(-50%);

    transition: transform 0.3s cubic-bezier(0.25, 0.8, 0.5, 1),
      top 0.3s cubic-bezier(0.25, 0.8, 0.5, 1),
      left 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  }

  .itemsList {
    border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: 4px;
    min-height: 38px;
    width: 100%;
    padding: 5px;

    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .providerChip {
      margin: 2px;

      p {
        margin: 0;
      }

      p:hover {
        cursor: pointer;
        color: #2b0803;
        font-weight: bold;
      }
    }

    .closeIcon:hover {
      color: red;
    }
  }

  .inputControl {
    display: flex;
    width: 100%;
    margin-bottom: 8px;

    .action {
      margin-left: 5px;
      // height: 56px;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        color: red;
      }
    }
  }
  .MediumButtonSlot {
    margin-left: 20px;
    height: 40px;
    min-width: 40px;
    padding: 0;
  }
}

.fieldContainer.hasError {
  .itemsList {
    border-color: #ff5252;
  }

  .label {
    color: #ff5252;
  }
}
</style>
