<template>
  <div id="disconnected">
    <left-view />
    <router-view />
  </div>
</template>

<script>
import LeftView from "../components/login/LeftView.vue";

export default {
  components: { LeftView },
  name: "DisconnectedLayout"
};
</script>

<style lang="scss">
* {
  box-sizing: border-box;
}

#disconnected {
  display: flex;
}
</style>
